<div fxLayout="row wrap" fxLayoutAlign="start stretch" class="modal-content">
  <div fxFlex="100" class="center-div" [ngClass.xs]="'mb-2'">
    <p class="modal-title" >
      {{this.title || '' }}
    </p>
  </div>
  <div fxFlex="100" class="center-v center-h" [ngClass.xs]="'mb-2'">
    <div class="modal-text content-body" [innerHTML]="this.datos | sanitizer:'html'"></div>
  </div>
  <div fxFlex="100" fxLayout="row">
    <div fxFlex="100" class="center-div"
      [ngClass]="'mt-2'">
      <button mat-raised-button class="button-round-active-2 button-close"
        (click)="onClose()">CERRAR
      </button>
    </div>
  </div>
</div>
