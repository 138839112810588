import { ExtendedEnvironment } from '../app/shared/models/extended-enviroments.models';

/**
 * Configuración del entorno
 */
export const environment: ExtendedEnvironment = {
    production: true,
    googleCaptcha: '6LfqKCcTAAAAADUAqXTkky_Q9i66RD1S0sHTb6xu',
    signingAesKey: 'F3gLZRh7Z7meR7hQx3f1SOrfgGPxMaGE',
    identityServer: {
    },
    apis: {
      acl: 'https://contacto.porvenir.com.co/access-control-list/api',
      'type-document': 'https://contacto.porvenir.com.co/type-document/api',
      'type-customer': 'https://contacto.porvenir.com.co/type-customer/api',
      'query-subject': 'https://contacto.porvenir.com.co/query-subject/api',
      'on-base': 'https://contacto.porvenir.com.co/on-base/api',
      interaction: 'https://contacto.porvenir.com.co/interaction/api',
      'type-treatment': 'https://contacto.porvenir.com.co/type-treatment/api',
    },
    environmentName: 'Staging',
};
