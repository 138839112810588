import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NexaConfigurationService } from '@nexa/configuration';
import { CaptchaApiService, InteractionApiService } from '@prf/interaction';
import { OnBaseApiService } from '@prf/on-base';
import { QuerySubjectApiService } from '@prf/query-subject';
import { TypeCustomerApiService } from '@prf/type-customer';
import { TypeDocumentApiService } from '@prf/type-document';
import { WsDataTreatmentApiService } from '@prf/ws-data-treatment';
import { RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings, RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { NgxSpinnerModule } from 'ngx-spinner';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorComponent } from './components/error/error.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { ContactUsComponent } from './core/components/contact-us/contact-us.component';
import { ContactUsFacade } from './core/components/contact-us/contact-us.facade';
import { FileUploadComponent } from './core/components/file-upload/file-upload.component';
import { FileUploadFacade } from './core/components/file-upload/file-upload.facade';
import { FooterComponent } from './core/components/footer/footer.component';
import { HeaderComponent } from './core/components/header/header.component';
import { HomeComponent } from './core/components/home/home.component';
import { MainComponent } from './core/components/main/main.component';
import { ModalsComponent } from './core/components/modals/modals.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AuthenticationService } from './services/authentication.service';
import { GlobalState } from './shared/base/class/global.state';
import { SanitizerPipe } from './shared/pipes/sanitizer/sanitizer.pipe';
import { SortedPipe } from './shared/pipes/sorted/sorted.pipe';
import { SortedTypeDocumentPipe } from './shared/pipes/sorted-type-document/sorted-type-document.pipe';
import { EncryptService } from './shared/services/encrypt/encrypt.service';

/**
 * Modulo Principal
 */
@NgModule({
  declarations: [
    ContactUsComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    HomeComponent,
    ErrorComponent,
    UnauthorizedComponent,
    FileUploadComponent,
    SortedPipe,
    SortedTypeDocumentPipe,
    ModalsComponent,
    SanitizerPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    CoreModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatCardModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatCheckboxModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    MatDialogModule,
  ],
  providers: [
    FileList,
    FileUploadFacade,
    GlobalState,
    NexaConfigurationService,
    ContactUsFacade,
    TypeDocumentApiService,
    TypeCustomerApiService,
    QuerySubjectApiService,
    OnBaseApiService,
    InteractionApiService,
    CaptchaApiService,
    AuthenticationService,
    WsDataTreatmentApiService,
    EncryptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.googleCaptcha } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'es',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: NexaConfigurationService) => () => configService.loadConfigurations(environment).toPromise(),
      deps: [NexaConfigurationService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
