import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Servicio encargado de los datos de Autenticación
 */
@Injectable()
export class AuthenticationService {
  /**
   * Propiedad de control para determinar si se encuentra autenticado
   */
  private isAuthenticated: boolean;

  /**
   * Propiedad que contiene el access token
   */
  private token: string;

  /**
   * Gets the is authenticated
   */
  public get getAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Método para hacer establecer si es autenticado
   * @param isAuthenticated Valor booleano a establecer
   */
  public set setAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
  }

  /**
   * Método para establecer el valor del Token
   * @param token Valor del Token a establecer
   */
  public set setToken(token: string) {
    this.token = token;
  }

  /**
   * Crea una nueva instancia de @see AuthenticationService
   */
  public constructor() {
    this.isAuthenticated = false;
    this.token = '';
  }

  /**
   * Metodo encargado de construir los header para peticiones Http
   * @returns Retorna un objeto HttpHeaders con el token y content-type
   */
  public getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();

    headers = headers.set('Content-Type', 'application/json');

    return this.appendAuthorizationHeader(headers);
  }

  /**
   * Metodo encargado de asociar el token al header Authorization
   * @param headers Objeto al cual se le asociara el header Authorization con el token
   * @returns Retorna el objeto HttpHeaders con el encabezado Authorization
   */
  private appendAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
    if (this.token === '') {
      return headers;
    }

    const tokenValue = `Bearer ${this.token}`;

    return headers.set('Authorization', tokenValue);
  }
}
