import { Injectable } from '@angular/core';
import { ReCaptchaDto } from '@prf/interaction';
import { QuerySubjectDto } from '@prf/query-subject';
import { TypeCustomerDto } from '@prf/type-customer';
import { TypeDocumentDto } from '@prf/type-document';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';

import { FileData } from '../../../core/Models/file-data.model';

/**
 * State global de la aplicación
 */
@Injectable()
export class GlobalState {
  /**
   * BehaviorSubject - Tipos de documentos
   */
  private readonly bsTypeDocuments = new BehaviorSubject<TypeDocumentDto[]>([]);
  /**
   * BehaviorSubject - Tipos de cliente
   */
  private readonly bsTypeCustomers = new BehaviorSubject<TypeCustomerDto[]>([]);
  /**
   * BehaviorSubject - Asuntos de consulta
   */
  private readonly bsQuerySubjects = new BehaviorSubject<QuerySubjectDto[]>([]);
  /**
   * BehaviorSubject - Client Transaction
   */
  private readonly bsFileUpload = new BehaviorSubject<FileData[]>([]);
  /**
   * Subject ReCaptcha
   */
  private readonly subjectReCaptcha = new Subject<ReCaptchaDto>();
  /**
   * Subject - subjectToken
   */
  private readonly subjectToken = new Subject<string>();
  /**
   * Subject - subjectDisableBton
   */
  private readonly subjectDisableBton = new BehaviorSubject<boolean>(false);
  /**
   * Información de Tipos de documentos Dto
   */
  public readonly typeDocuments$ = this.bsTypeDocuments.asObservable();
  /**
   * Información de Tipos de clientes Dto
   */
  public readonly typeCustomers$ = this.bsTypeCustomers.asObservable();
  /**
   * Información de Asuntos de consulta Dto
   */
  public readonly querySubjects$ = this.bsQuerySubjects.asObservable();
  /**
   * Información de Client Transaction
   */
  public fileUpload$ = this.bsFileUpload.asObservable();
  /**
   * Observable con ReCaptcha
   */
  public readonly reCaptcha$ = this.subjectReCaptcha.asObservable();
  /**
   * Observable con token
   */
  public token$ = this.subjectToken.asObservable();
  /**
   * Observable con boton desactivado
   */
  public disableButtonFileUp$ = this.subjectDisableBton.asObservable();

  /**
   * Metodo encargado de asignar Tipos de documentos
   * @param data Información de Tipos de documentos a asignar
   */
  public setTypeDocuments(data: TypeDocumentDto[]): void {
    this.bsTypeDocuments.next(data);
  }

  /**
   * Metodo encargado de asignar Tipos de clientes
   * @param data Información de Tipos de clientes a asignar
   */
  public setTypeCustomers(data: TypeCustomerDto[]): void {
    this.bsTypeCustomers.next(data);
  }

  /**
   * Metodo encargado de asignar Asuntos de consulta
   * @param data Información de Asuntos de consulta a asignar
   */
  public setQuerySubjects(data: QuerySubjectDto[]): void {
    this.bsQuerySubjects.next(data);
  }

  /**
   * Método en cargado de almacenar la información del archivo
   * @param fileUpload archivo
   */
  public setFileUploaded(fileUpload: FileData) {
    const cloneFileUploaded = _.cloneDeep(this.bsFileUpload.value);
    cloneFileUploaded.push(fileUpload);
    this.bsFileUpload.next(cloneFileUploaded);
  }

  /**
   * Método en cargado de almacenar la información de los archivos
   * @param fileUpload archivos
   */
  public setFileUpload(fileUpload: FileData[]) {
    this.bsFileUpload.next(fileUpload);
  }

  /**
   * Método en cargado de almacenar la información del estado del boton
   * @param disableButtonFileUp boton
   */
  public setDisableButtonFileUpGlobalState(disableButtonFileUp: boolean) {
    this.subjectDisableBton.next(disableButtonFileUp);
  }

  /**
   * @description Método encargado de establecer el ReCaptcha
   *
   * @param recaptcha recaptcha
   */
  public setReCaptcha(recaptcha: ReCaptchaDto) {
    this.subjectReCaptcha.next(recaptcha);
  }

  /**
   * @description Metodo encargado de asignar el resultado de token JWT
   * @param data Información de la token JWT
   */
  public setToken(token: string) {
    this.subjectToken.next(token);
  }
}
