import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe para ordenar la información de un array de objetos
 */
@Pipe({
  name: 'sortedTypeDocument',
})
export class SortedTypeDocumentPipe implements PipeTransform {
  /**
   * Metodo encargado de tranformar la información
   * @param value Arreglo que se va a ordenar
   * @param descending Argumento que indica si el ordenamiento es o no descendente
   */
  public transform(value: any[] | null): any[] {
    // Verifica si el valor es nulo o no es un array
    if (!value || !Array.isArray(value)) {
      // Devuelve un array vacío so el valor es nulo
      return [];
    }  

    // Orden predefinido de las propiedades "mask"
    const order = [
      'CC',
      'CE',
      'NIT',
      'PT - PPT',
      'PS',
      'RC',
      'TI',
      'DIP',
      'PONU',
      'AS',
      'ME',
      'CN',
      'CU',
      'SC'
    ];

    // Función de comparación personalizada para ordenar los objetos según el orden predefinido
    const compareFn = (a: any, b: any): number => {
      // Obtiene el índice de la propiedad "mask" de cada objeto en el orden predefinido
      const indexA = order.indexOf(a.mask);
      const indexB = order.indexOf(b.mask);
      // Devuelve la diferencia entre los índices para determinar el orden de clasificación
      return indexA - indexB;
    };

    // Ordenar el array de objetos utilizando la función de comparación personalizada
    return value.sort(compareFn);
  }
}
