import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { FacadeBase } from '../../../shared/base/class/facade.base';
import { GlobalState } from '../../../shared/base/class/global.state';
import { FileData } from '../../Models/file-data.model';

/**
 * Facade para el cargue de archivos
 */
@Injectable()
export class FileUploadFacade extends FacadeBase {

  /**
   * Observable que contiene la información de los archivos subidos
   */
  public get dataSelected$(): Observable<FileData[]> {
    return this.state.fileUpload$;
  }

  /**
   * Crea una nueva instancia de FileUpload
   * @param state estado global
   * @param injector Servicio para obetner acceso a dependency control
   */
  public constructor(state: GlobalState, injector: Injector) {
    super(injector, state);
  }

  /**
   * Método en cargado de almacenar la información del archivo
   * @param fileUpload archivo
   */
  public setFileFileUploaded(fileUpload: FileData) {
    this.state.setFileUploaded(fileUpload);
  }

  /**
   * Método en cargado de almacenar la información de los archivos
   * @param fileUpload archivos
   */
  public setFileFileUpload(fileUpload: FileData[]) {
    this.state.setFileUpload(fileUpload);
  }

  /**
   * Método para establecer el valor de la variable disablebottonfileup
   * @param value Valor para disablebottonfileup
   */

  public setDisableButtonFileUp(disableButtonFileUp: boolean) {
    this.state.setDisableButtonFileUpGlobalState(disableButtonFileUp);
  }
}
