import { Injectable } from '@angular/core';
import { environment } from 'apps/website/src/environments/environment';
import * as CryptoJS from 'crypto-js';

/**
 * Servicio encargado de administrar los tipos de do
 */
@Injectable()
export class EncryptService {

  /**
   * Llave privada
   */
  private readonly key = CryptoJS.enc.Utf8.parse(environment.signingAesKey);
  /**
   * Algoritmo
   */
  private readonly iv = CryptoJS.enc.Utf8.parse(environment.signingAesKey.substring(0, 16));

  /**
   * Metodo encargado de encriptar informacion
   */
  public toEncrypt(data: string): string {

    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data.toString()), this.key, { iv: this.iv });

    return encrypted.toString();
  }

  /**
   * Metodo encargado de desencriptar informacion
   */
  public toDecrypt(data: string): string {

    const decrypted = CryptoJS.AES.decrypt(data, this.key, { iv: this.iv });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}
