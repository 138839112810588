import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * Componente del Home
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  /**
   * Un servicio que proporciona navegación entre vistas y capacidades de manipulación de URL.
   */
  private readonly router: Router;
  /**
   * Proporciona acceso a información sobre una ruta asociada a un componente.
   */
  private readonly activatedRoute: ActivatedRoute;

  public constructor(router: Router, activatedRoute: ActivatedRoute) {
    this.router = router;
    this.activatedRoute = activatedRoute;
  }

  public ngOnInit(): void {
  }

  public onContact() {
    this.router.navigate(['../contact'], { relativeTo: this.activatedRoute });
  }

  /**
   * Método encargado de redirecionamiento al sitio de chat
   */
  public onLink() {
    location.href = 'https://chat.porvenir.com.co/';
  }

}
