<div fxLayout="row wrap" fxLayoutAlign="center center">
  <mat-card class="card-container">
    <mat-card-header>
      <div fxLayout="column wrap" class="titles-container">
        <div
          fxLayout="column"
          fxLayoutAlign="start end"
          fxLayoutAlign.md="center center"
          fxLayoutAlign.sm="center center"
          fxLayoutAlign.xs="center center"
        >
          <p class="titles-h2 write-form">Diligencia el formulario:</p>
        </div>
        <div
          fxLayout="column"
          fxFlexOffset="10px"
          fxLayoutAlign="center start"
          fxLayoutAlign.md="center center"
          fxLayoutAlign.sm="center center"
          fxLayoutAlign.xs="center center"
        >
          <p class="body-regular-text write-fields">
            Los campos marcados con <span class="asterisk">*</span> son obligatorios.
          </p>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <ngx-spinner bdColor="rgba(51,51,51,0.6)" size="medium" [fullScreen]="true" type="timer">
        <p class="spinner">Cargando...</p>
      </ngx-spinner>
      <form [formGroup]="frmGroup">
        <div fxLayout="column" class="card-content-container">
          <!--Row Or Column #1-->
          <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" class="row-fields">
            <!--Solicitante-->
            <div fxLayout="column wrap" fxFlex="48.2" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="column">
                <label class="labels-form"><span class="asterisk">*</span>&nbsp;¿Quién hace la solicitud?</label>
              </div>
              <div fxLayout="column" class="box">
                <select
                  formControlName="typeCustomer"
                  [required]="frmGroup.controls.typeCustomer.invalid"
                  (change)="modalTypeCustomer()"
                  [ngClass]="{
                    'is-danger-select':
                      frmGroup.controls.typeCustomer.invalid &&
                      (frmGroup.controls.typeCustomer.dirty || frmGroup.controls.typeCustomer.touched)
                  }"
                >
                  <option [value]="''">Seleccione...</option>
                  <option *ngFor="let item of typeCustomers$ | async | sorted: 'name'" [ngValue]="item">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center">
                <span
                  class="invalid-field"
                  *ngIf="frmGroup.controls.typeCustomer.hasError('required') && frmGroup.controls.typeCustomer.touched"
                  >¡Este campo es obligatorio!</span
                >
              </div>
            </div>
            <!--FILL-->
            <div fxLayout="column wrap" fxFlex>
              <div fxFlexFill></div>
            </div>
          </div>
          <!--Row Or Column #2-->
          <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" class="row-fields">
            <!--Tipo de documento-->
            <div
              fxLayout="column wrap"
              fxLayout.xs="row wrap"
              fxLayout.sm="row wrap"
              fxFlex="48.2"
              fxFlex.md="100"
              fxFlex.sm="100"
              fxFlex.xs="100"
            >
              <div fxLayout="row">
                <label class="labels-form"><span class="asterisk">*</span>&nbsp;Tipo de documento</label>
              </div>
              <div fxLayout="row" fxFlex.sm="100" fxFlex.xs="100" class="box">
                <select
                  formControlName="typeDocument"
                  [required]="frmGroup.controls.typeDocument.invalid"
                  [ngClass]="{
                    'is-danger-select':
                      frmGroup.controls.typeDocument.invalid &&
                      (frmGroup.controls.typeDocument.dirty || frmGroup.controls.typeDocument.touched)
                  }"
                >
                  <option [value]="''">Seleccione...</option>
                  <option *ngFor="let item of typeDocuments$ | async | sortedTypeDocument: 'mask'" [ngValue]="item">
                    {{ item.mask }} - {{ item.name }}
                  </option>
                </select>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span
                  class="invalid-field"
                  *ngIf="frmGroup.controls.typeDocument.hasError('required') && frmGroup.controls.typeDocument.touched"
                  >¡Este campo es obligatorio!</span
                >
              </div>
            </div>
            <!--FILL-->
            <div fxLayout="column wrap" fxFlex="3" fxFlexOffset.sm="30px" fxFlexOffset.xs="30px">
              <div fxFlexFill></div>
            </div>
            <!--Número de documento-->
            <div
              fxLayout="column wrap"
              fxLayout.xs="row wrap"
              fxLayout.sm="row wrap"
              fxFlex="48.2"
              fxFlex.md="100"
              fxFlex.sm="100"
              fxFlex.xs="100"
            >
              <div fxLayout="row">
                <label class="labels-form"><span class="asterisk">*</span>&nbsp;Número de documento</label>
              </div>
              <div fxLayout="row" fxFlex.sm="100" fxFlex.xs="100">
                <input
                  type="text"
                  formControlName="numberDocument"
                  style="width: 100%"
                  [required]="frmGroup.controls.numberDocument.invalid"
                  [ngClass]="{
                    'is-danger-select':
                      frmGroup.controls.numberDocument.invalid &&
                      (frmGroup.controls.numberDocument.dirty || frmGroup.controls.numberDocument.touched)
                  }"
                />
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span
                  class="invalid-field"
                  *ngIf="
                    frmGroup.controls.numberDocument.hasError('required') && frmGroup.controls.numberDocument.touched
                  "
                  >¡Este campo es obligatorio!</span
                >
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span class="invalid-field" *ngIf="frmGroup.controls.numberDocument.hasError('pattern')"
                  >¡Numero de documento invalido!</span
                >
              </div>
            </div>
          </div>
          <!--Row Or Column #3-->
          <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" class="row-fields">
            <!--Nombre completo o razón social-->
            <div fxLayout="column wrap" fxFlex="100">
              <div fxLayout="row">
                <label class="labels-form"><span class="asterisk">*</span>&nbsp;Nombre completo razón social</label>
              </div>
              <div fxLayout="row">
                <input
                  type="text"
                  formControlName="name"
                  style="width: 100%"
                  [required]="frmGroup.controls.name.invalid"
                  [ngClass]="{
                    'is-danger-select':
                      frmGroup.controls.name.invalid && (frmGroup.controls.name.dirty || frmGroup.controls.name.touched)
                  }"
                />
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span
                  class="invalid-field"
                  *ngIf="frmGroup.controls.name.hasError('required') && frmGroup.controls.name.touched"
                  >¡Este campo es obligatorio!</span
                >
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span class="invalid-field" *ngIf="frmGroup.controls.name.hasError('pattern')">¡Nombre invalido!</span>
              </div>
            </div>
          </div>
          <!--Row Or Column #4-->
          <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" class="row-fields">
            <!--Correo electronico-->
            <div
              fxLayout="column wrap"
              fxLayout.xs="row wrap"
              fxLayout.sm="row wrap"
              fxFlex="48.2"
              fxFlex.md="100"
              fxFlex.sm="100"
              fxFlex.xs="100"
            >
              <div class="container-tooltip-email" fxLayout="row">
                <label class="labels-form label-info"
                  ><span class="asterisk">*</span>&nbsp;Correo electronico&nbsp;<img
                    class="info-image"
                    src="../../../../assets/images/Gráficos-12.svg"
                /></label>
                <div class="tooltip-email" fxLayoutAlign="center center">
                  <p>
                    Por tu seguridad, las respuestas con información confidencial serán enviadas al correo que tengas
                    registrado en Porvenir.
                  </p>
                </div>
              </div>
              <div fxLayout="row" fxFlex.sm="100" fxFlex.xs="100">
                <input
                  type="text"
                  formControlName="email"
                  style="width: 100%"
                  [required]="frmGroup.controls.email.invalid"
                  [ngClass]="{
                    'is-danger-select':
                      frmGroup.controls.email.invalid &&
                      (frmGroup.controls.email.dirty || frmGroup.controls.email.touched)
                  }"
                />
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span class="message-email-info" *ngIf="frmGroup.controls.email.invalid"
                  >A este correo te daremos respuesta sobre información no sensible</span
                >
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span
                  class="invalid-field"
                  *ngIf="frmGroup.controls.email.hasError('required') && frmGroup.controls.email.touched"
                  >¡Este campo es obligatorio!</span
                >
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span class="invalid-field" *ngIf="frmGroup.controls.email.hasError('pattern')">¡Email invalido!</span>
              </div>
            </div>
            <!--FILL-->
            <div fxLayout="column wrap" fxFlex="3" fxFlexOffset.sm="30px" fxFlexOffset.xs="30px">
              <div fxFlexFill></div>
            </div>
            <!--Teléfono de contacto-->
            <div
              fxLayout="column wrap"
              fxLayout.xs="row wrap"
              fxLayout.sm="row wrap"
              fxFlex="48.2"
              fxFlex.md="100"
              fxFlex.sm="100"
              fxFlex.xs="100"
            >
              <div fxLayout="row">
                <label class="labels-form"><span class="asterisk">*</span>&nbsp;Teléfono de contacto</label>
              </div>
              <div fxLayout="row" fxFlex.sm="100" fxFlex.xs="100">
                <input
                  type="text"
                  formControlName="phone"
                  style="width: 100%"
                  [required]="frmGroup.controls.phone.invalid"
                  [ngClass]="{
                    'is-danger-select':
                      frmGroup.controls.phone.invalid &&
                      (frmGroup.controls.phone.dirty || frmGroup.controls.phone.touched)
                  }"
                />
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span
                  class="invalid-field"
                  *ngIf="frmGroup.controls.phone.hasError('required') && frmGroup.controls.phone.touched"
                  >¡Este campo es obligatorio!</span
                >
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span class="invalid-field" *ngIf="frmGroup.controls.phone.hasError('pattern')"
                  >¡Telefono invalido!</span
                >
              </div>
            </div>
          </div>
          <!--Row Or Column #5-->
          <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" class="row-fields">
            <!--País-->
            <div
              fxLayout="column wrap"
              fxLayout.xs="row wrap"
              fxLayout.sm="row wrap"
              fxFlex="48.2"
              fxFlex.md="100"
              fxFlex.sm="100"
              fxFlex.xs="100"
            >
              <div fxLayout="row">
                <label class="labels-form"><span class="asterisk">*</span>&nbsp;País</label>
              </div>
              <div fxLayout="row" fxFlex.sm="100" fxFlex.xs="100">
                <input
                  type="text"
                  formControlName="country"
                  style="width: 100%"
                  [required]="frmGroup.controls.country.invalid"
                  [ngClass]="{
                    'is-danger-select':
                      frmGroup.controls.country.invalid &&
                      (frmGroup.controls.country.dirty || frmGroup.controls.country.touched)
                  }"
                />
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span
                  class="invalid-field"
                  *ngIf="frmGroup.controls.country.hasError('required') && frmGroup.controls.country.touched"
                  >¡Este campo es obligatorio!</span
                >
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span class="invalid-field" *ngIf="frmGroup.controls.country.hasError('pattern')">¡Pais invalido!</span>
              </div>
            </div>
            <!--FILL-->
            <div fxLayout="column wrap" fxFlex="3" fxFlexOffset.sm="30px" fxFlexOffset.xs="30px">
              <div fxFlexFill></div>
            </div>
            <!--Departamento-->
            <div
              fxLayout="column wrap"
              fxLayout.xs="row wrap"
              fxLayout.sm="row wrap"
              fxFlex="48.2"
              fxFlex.md="100"
              fxFlex.sm="100"
              fxFlex.xs="100"
              fxFlex
            >
              <div fxLayout="row">
                <label class="labels-form"><span class="asterisk">*</span>&nbsp;Departamento/Estado/Provincia</label>
              </div>
              <div fxLayout="row" fxFlex.sm="100" fxFlex.xs="100">
                <input
                  type="text"
                  formControlName="department"
                  style="width: 100%"
                  [required]="frmGroup.controls.department.invalid"
                  [ngClass]="{
                    'is-danger-select':
                      frmGroup.controls.department.invalid &&
                      (frmGroup.controls.department.dirty || frmGroup.controls.department.touched)
                  }"
                />
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span
                  class="invalid-field"
                  *ngIf="frmGroup.controls.department.hasError('required') && frmGroup.controls.department.touched"
                  >¡Este campo es obligatorio!</span
                >
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span class="invalid-field" *ngIf="frmGroup.controls.department.hasError('pattern')"
                  >¡Departamento invalido!</span
                >
              </div>
            </div>
          </div>
          <!--Row Or Column #6-->
          <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" class="row-fields">
            <!--Municipio-->
            <div
              fxLayout="column wrap"
              fxLayout.xs="row wrap"
              fxLayout.sm="row wrap"
              fxFlex="48.2"
              fxFlex.md="100"
              fxFlex.sm="100"
              fxFlex.xs="100"
            >
              <div fxLayout="row">
                <label class="labels-form"><span class="asterisk">*</span>&nbsp;Municipio/Ciudad</label>
              </div>
              <div fxLayout="row" fxFlex.sm="100" fxFlex.xs="100">
                <input
                  type="text"
                  formControlName="municipality"
                  style="width: 100%"
                  [required]="frmGroup.controls.municipality.invalid"
                  [ngClass]="{
                    'is-danger-select':
                      frmGroup.controls.municipality.invalid &&
                      (frmGroup.controls.municipality.dirty || frmGroup.controls.municipality.touched)
                  }"
                />
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span
                  class="invalid-field"
                  *ngIf="frmGroup.controls.municipality.hasError('required') && frmGroup.controls.municipality.touched"
                  >¡Este campo es obligatorio!</span
                >
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span class="invalid-field" *ngIf="frmGroup.controls.municipality.hasError('pattern')"
                  >¡Municipio invalido!</span
                >
              </div>
            </div>
            <!--FILL-->
            <div fxLayout="column wrap" fxFlex="3" fxFlexOffset.sm="30px" fxFlexOffset.xs="30px">
              <div fxFlexFill></div>
            </div>
            <!--Dirección-->
            <div
              fxLayout="column wrap"
              fxLayout.xs="row wrap"
              fxLayout.sm="row wrap"
              fxFlex="48.2"
              fxFlex.md="100"
              fxFlex.sm="100"
              fxFlex.xs="100"
            >
              <div fxLayout="row">
                <label class="labels-form"><span class="asterisk">*</span>&nbsp;Dirección</label>
              </div>
              <div fxLayout="row" fxFlex.sm="100" fxFlex.xs="100">
                <input
                  type="text"
                  formControlName="address"
                  style="width: 100%"
                  [required]="frmGroup.controls.address.invalid"
                  [ngClass]="{
                    'is-danger-select':
                      frmGroup.controls.address.invalid &&
                      (frmGroup.controls.address.dirty || frmGroup.controls.address.touched)
                  }"
                />
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <span
                  class="invalid-field"
                  *ngIf="frmGroup.controls.address.hasError('required') && frmGroup.controls.address.touched"
                  >¡Este campo es obligatorio!</span
                >
              </div>
            </div>
          </div>
          <!--Row Or Column #7-->
          <div fxLayout="row" fxLayout.sm="column" class="row-fields">
            <!--Asunto-->
            <div fxLayout="column wrap" fxFlex="48.2" fxFlex.md="100" fxFlex.sm="100" fxFlex.xs="100">
              <div fxLayout="column">
                <label class="labels-form"><span class="asterisk">*</span>&nbsp;Asunto de tu consulta</label>
              </div>
              <div fxLayout="column" class="box">
                <select
                  formControlName="subject"
                  [required]="frmGroup.controls.subject.invalid"
                  [ngClass]="{
                    'is-danger-select':
                      frmGroup.controls.subject.invalid &&
                      (frmGroup.controls.subject.dirty || frmGroup.controls.subject.touched)
                  }"
                >
                  <option [value]="''">Seleccione...</option>
                  <option *ngFor="let item of querySubject$ | async | sorted: 'name'" [ngValue]="item">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div fxLayout="column" fxLayoutAlign="center center">
                <span
                  class="invalid-field"
                  *ngIf="frmGroup.controls.subject.hasError('required') && frmGroup.controls.subject.touched"
                  >¡Este campo es obligatorio!</span
                >
              </div>
            </div>
            <!--FILL-->
            <div fxLayout="column wrap" fxFlex>
              <div fxFlexFill></div>
            </div>
          </div>
          <!--Row Or Column #8-->
          <div fxLayout="row" fxLayout.sm="column" class="row-fields">
            <!--Detalle-->
            <div fxLayout="column wrap" fxFlex>
              <div fxLayout="column">
                <label class="labels-form"><span class="asterisk">*</span>&nbsp;Detalle de tu consulta</label>
              </div>
              <div fxLayout="column">
                <textarea
                  maxlength="500"
                  formControlName="detailSubject"
                  (mousedown)="count()"
                  (change)="count()"
                  (keyup)="count()"
                  [required]="frmGroup.controls.detailSubject.invalid"
                  [ngClass]="{
                    'is-danger-select':
                      frmGroup.controls.detailSubject.invalid &&
                      (frmGroup.controls.detailSubject.dirty || frmGroup.controls.detailSubject.touched)
                  }"
                ></textarea>
              </div>

              <div fxLayout="column" fxLayoutAlign="center center">
                <span
                  class="invalid-field"
                  *ngIf="
                    frmGroup.controls.detailSubject.hasError('required') && frmGroup.controls.detailSubject.touched
                  "
                  >¡Este campo es obligatorio!</span
                >
              </div>

              <div fxLayout="column" class="warn-message-box description">
                <span *ngIf="!frmGroup.controls.detailSubject.hasError('required')"
                  >{{ this.valor }}|500 Carácteres</span
                >
              </div>

              <div fxLayout="column" class="warn-message-box error description-error">
                <span
                  *ngIf="
                    frmGroup.controls.detailSubject.hasError('required') && frmGroup.controls.detailSubject.touched
                  "
                  >{{ this.valor }}|500 Carácteres</span
                >
              </div>
            </div>
          </div>
          <div fxLayout="row wrap" class="row-fields">
            <p class="text-attachment-warning-message">
              Ten en cuenta al adjuntar documentos a tu solicitud validar el peso de máximo de 9 MB así como los
              formatos permitidos JPG y PDF y que estos archivos no este protegidos con contraseñas, no estén
              comprimidos (extensiones como zip, rar, otros) o que estén dañados, toda vez que no están permitidos y tu
              solicitud no podrá ser atendida.
            </p>
          </div>
          <!--Div app-file-upload-->
          <div>
            <app-file-upload></app-file-upload>
          </div>
          <!--Row Or Column Data Treatment-->
          <div fxLayout="row wrap" class="row-fields" fxFlexOffset="-40px">
            <div class="container-habeas-data" fxLayout="column wrap" fxFlex="100">
              <!--Check Authorize Habeas Data-->
              <div class="text-check-box" fxLayout="row" fxLayoutAlign="start center" fxFlex="10" fxFlex.md="20">
                <div fxlayout="column">
                  <a>Si</a>
                </div>
                <div class="text-check-box-no" fxlayout="column">
                  <a>No</a>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="10" fxFlex.md="20">
                <mat-checkbox formControlName="dataTreatmentYes" (change)="toggleCheckboxData('dataTreatmentYes')"required></mat-checkbox>
                <mat-checkbox class="check-box-no" formControlName="dataTreatmentNo" (change)="toggleCheckboxData('dataTreatmentNo')"></mat-checkbox>
                <span class="text-habeas-data">He leído y acepto la <a class = "link-habeas-data" (click)="getTerminosyCondiciones()">autorización de tratamiento de datos</a></span>
              </div>
              <!--Text Habeas Data-->

            </div>
          </div>
          <!--Row Or Column acceptTerms-->
          <div fxLayout="row wrap" class="row-fields" fxFlexOffset="-40px">
            <div class="container-habeas-data" fxLayout="column wrap" fxFlex="100">
              <!--Check Authorize Habeas Data-->
              <div class="text-check-box" fxLayout="row" fxLayoutAlign="start center" fxFlex="10" fxFlex.md="20">
                <div fxlayout="column">
                  <a>Si</a>
                </div>
                <div class="text-check-box-no" fxlayout="column">
                  <a>No</a>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxFlex="10" fxFlex.md="20">
                <mat-checkbox formControlName="acceptedTermsYes" (change)="toggleCheckboxTerms('acceptedTermsYes')" required></mat-checkbox>
                <mat-checkbox class="check-box-no" formControlName="acceptedTermsNo" (change)="toggleCheckboxTerms('acceptedTermsNo')" ></mat-checkbox>
                <span class="text-habeas-data">He leído y acepto <a class = "link-habeas-data" (click)="getTerminosyCondiciones2()">términos y condiciones del servicio</a></span>
              </div>
              <!--Text Habeas Data-->
            </div>
          </div>
          <!--Row Or Column Text Warning No Habeas Data Checking-->
          <div fxLayout="row wrap" class="row-fields" fxFlexOffset="-55px" fxLayoutAlign="center center">
            <div fxLayout="row">
              <p class="text-error-habeas-data">¡Para envíar debes autorizar el tratamiento de datos y términos y condiciones!</p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <!--Row Or Column Recaptcha-->
          <div fxLayout="row wrap" fxFlexOffset="10px" fxLayoutAlign="center center" fxLayoutAlign.xs="center center">
            <div fxLayout="row">
              <re-captcha class="g-recaptcha center-align" formControlName="recaptchaReactive"></re-captcha>
            </div>
          </div>
          <br />
          <br />
          <br />
          <!--Row Or Column Button Send-->
          <div fxLayout="row wrap" fxFlexOffset="35px" fxLayoutAlign="center center">
            <div fxLayout="row">
              <button
                mat-raised-button
                color="primary"
                class="button-round-active-2 button-send"
                [disabled]="frmGroup.invalid"
                (click)="onSubmit()"
              >
                <span class="titles-h3 text-button-send">Enviar</span>
              </button>
            </div>
          </div>
          <br />
          <br />
          <!--Row Or Column Information-->
          <div fxLayout="row wrap" fxLayoutAlign="center center">
            <div fxLayout="column wrap">
              <!--Logo Information-->
              <div fxLayout="column" fxLayoutAlign="center center" fxFlexOffset="-60px" fxFlexOffset="-40px">
                <div class="container-tooltip-info" fxLayoutAlign="center center">
                  <img class="image-info" src="../../../../assets/images/Gráficos-11.svg" width="150" height="150" />
                  <div class="tooltip-info" fxLayoutAlign="center center">
                    <p>
                      Dependiendo de tu solicitud los tiempos de respuesta pueden variar
                      <span class="relevant">entre 1 a 15 días habiles</span><br /><br />
                      La respuesta de tu solicitud no genera ningún costo y no existen intermediarios en el proceso.
                      Llegara un mensaje automatico de confirmacion de la recepcion de la solicitud correspondiente.
                    </p>
                  </div>
                </div>
              </div>
              <!--Text Information-->
              <div fxLayout="column" fxLayoutAlign="center center" fxFlexOffset="-50px">
                <p class="text-information">Información</p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
