import { Component, OnInit } from '@angular/core';

/**
 * Componente del Header
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public ngOnInit(): void {
  }

}
