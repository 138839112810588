<div class="banner-footer">
  <div fxLayout="column wrap" fxLayoutAlign="center center">
    <div fxLayout="row ">
      <div fxLayout="column">
        <p class="body-regular-text text-information-footer-2">
          Para diligenciar el formato de solicitud de información con
          identidad reservada dispuesto por la Procuraduría General de la Nación en su página web <a class="link-page" href="https://www.procuraduria.gov.co/portal/pqrsdf_Solicitud_de_informacion_con_identificacion_reservada.page">haz clic aquí</a>
        </p>
        <p class="body-regular-text text-information-footer-3">Cuentas con un Defensor del Consumidor Financiero, Dra. Ana María Giraldo Rincón ubicado en la
          carrera 10 # 97A-13 Oficina 502 en Bogotá, Teléfono: 601 610 81 64,
          defensoriaporvenir@legalcrc.com, quien dará trámite a tu queja de forma objetiva y gratuita.</p>
        <p class="body-regular-text text-information-footer-4">Te recomendamos usar los siguientes navegadores para que tu
          experiencia con este servicio sea optima:</p>
      </div>
    </div>
  </div>
  <div fxLayout="row">
    <div fxLayout="row wrap" fxLayout.xs="row wrap" fxFlex.xl="33.333" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="35" fxFlex.xs="40"
    fxLayoutAlign="center center">
    </div>
    <div fxLayout="row wrap" fxLayout.xs="row wrap" fxFlex.xl="33.33" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="35" fxFlex.xs="40"
    fxLayoutAlign="center center" >
      <div fxLayout="column">
        <img src="../../../../assets/images/IE-logo.png" class="logo-browser" />
        <p class="text-browser">Edge</p>
      </div>
      <div fxLayout="column">
        <img src="../../../../assets/images/chrome-logo.png" class="logo-browser" />
            <p class="text-browser align-text">50+</p>
      </div>
      <div fxLayout="column">
        <img src="../../../../assets/images/firefox-logo.png" class="logo-browser" />
            <p class="text-browser align-text">50+</p>
      </div>
      <div fxLayout="column">
        <img src="../../../../assets/images/safari-logo.png" class="logo-browser" />
            <p class="text-browser align-text">50+</p>
      </div>
    </div>
        <div fxLayout="row wrap" fxLayout.xs="column wrap" fxFlex.xl="33.33" fxFlex.lg="30" fxFlex.md="30" fxFlex.sm="35" fxFlex.xs="40"
          fxLayoutAlign="end center">
          <img src="../../../../assets/images/vigilado.png" class="logo-vigilado" />
        </div>
  </div>
</div>
