import { Injectable, Injector, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';

import { GlobalState } from './global.state';

/**
 * Clase base para la comunicación del modulo con el backend y el estado
 */
@Injectable()
export abstract class FacadeBase implements OnDestroy {
  /**
   * Estado global de la aplicacion
   */
  public readonly state: GlobalState;
  /**
   * Servicio para obetner acceso a dependency control
   */
  public readonly injector: Injector;
  /**
   * Servicio de spinner
   */
  public readonly spinner: NgxSpinnerService;
  /**
   * Observable para dar de baja las sucripciones
   */
  public readonly destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * Crea una nueva instancia de FacadeBase
   * @param state Administra el estado del modulo de clientes
   * @param injector Administra el estado del modulo de clientes
   */
  public constructor(injector: Injector, state: GlobalState) {
    this.state = state;
    this.injector = injector;
    this.spinner = this.injector.get(NgxSpinnerService);
  }

  /**
   * A lifecycle hook that is called when a directive, pipe, or service is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
