<div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutAlign.sm="center center"
  fxLayoutAlign.xs="center center">
  <mat-card class="card-container">
    <!--Column Welcome To Porvenir Contact Us-->
    <div fxLayout="column" class="card-container-image">
      <img mat-card-image class="card-banner-image" src="../../../../assets/images/banner-principal.png"
        alt="Banner Work" />
      <div fxLayout="column wrap" class="text-block-image">
        <div class="column">
          <p class="titles-h1 text-message-welcome-1">Bienvenido a tu</p>
        </div>
        <div class="column" fxFlexOffset="30px" fxFlexOffset.sm="10px" fxFlexOffset.xs="10px">
          <p class="titles-h1 text-message-welcome-2">Ventanilla Virtual</p>
        </div>
      </div>
    </div>
    <!--Column Autoservices And Contact Us-->
    <div fxLayout="column">
      <mat-card-content>
        <!--Column Autoservice-->
        <div fxLayout="row wrap" fxLayout.sm="column wrap" fxLayout.xs="column wrap" fxLayoutAlign="center start"
          fxLayoutAlign.sm="start center" fxLayoutAlign.xs="start center" class="container-autoservice">
          <div fxLayout="column" class="header-autoservice">
            <p class="titles-h1 text-header-autoservice">AUTOSERVICIOS</p>
          </div>
          <div fxLayout="column" fxLayout.sm="row wrap" fxLayout.xs="row wrap" class="content-autoservice"
            fxLayoutAlign="start center" fxLayoutAlign.sm="center start" fxLayoutAlign.xs="center start">
            <div fxLayout="column wrap">
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
                <p class="body-regular-text" id="title-autoservice-1">Para acceder a los siguientes servicios haz clic
                  en
                  el botón ingresar.
                </p>
              </div>
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center"
                fxFlexOffset="-2px">
                <p class="body-regular-text" id="title-autoservice-2">Recuerda que tú mismo puedes hacerlo <strong>en
                    línea.</strong></p>
              </div>
            </div>
            <!--Content Links Site External Porvenir-->
            <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" class="content-links">
              <!--Actualización de datos a un clic-->
              <div fxLayout="column" fxFlex fxLayoutAlign="start center">
                <div fxLayout="row" fxFlex="100">
                  <img class="link-image-2" src="../../../../assets/images/Grafica_Lupa.svg"
                      alt="Actualización de datos a un clic" />
                </div>
                <div fxLayout="row" fxFlex="100" fxFlexOffset="-50px" fxFlexOffset.sm="-30px" fxFlexOffset.xs="-35px">
                  <p class="text-link-image update-data">Actualiza tus datos</p>
                </div>
              </div>
              <!--Certificados y extractos-->
              <div fxLayout="column" fxFlex fxLayoutAlign="start center">
                <div fxLayout="row" fxFlex="100">
                  <img class="link-image" src="../../../../assets/images/Gráficos-04.svg"
                      alt="Certificados y extractos" />
                </div>
                <div fxLayout="row" fxFlexOffset="-50px" fxFlexOffset.sm="-30px" fxFlexOffset.xs="-35px">
                  <p class="text-link-image">Certificados y extractos</p>
                </div>
              </div>
              <!--Documentos retiro de Cesantías-->
              <div fxLayout="column" fxFlex fxLayoutAlign="start center">
                <div fxLayout="row" fxFlex="100">
                  <img class="link-image" src="../../../../assets/images/Gráficos-05.svg"
                      alt="Documentos retiro de Cesantías" />
                </div>
                <div fxLayout="row" fxFlexOffset="-50px" fxFlexOffset.sm="-30px" fxFlexOffset.xs="-35px">
                  <p class="text-link-image">Requisitos para retiro de cesantías</p>
                </div>
              </div>
              <!--Consulta de Saldos-->
              <div fxLayout="column" fxFlex fxLayoutAlign="start center">
                <div fxLayout="row" fxFlex="100">
                  <img class="link-image" src="../../../../assets/images/Gráficos-06.svg"
                      alt="Consulta de Saldos" />
                </div>
                <div fxLayout="row" fxFlexOffset="-50px" fxFlexOffset.sm="-30px" fxFlexOffset.xs="-35px">
                  <p class="text-link-image">Consulta de
                    Saldos</p>
                </div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <button mat-raised-button color="primary" class="button-round-active-3 button-enter" (click)="onLink()">Ingresar</button>
            </div>
          </div>
        </div>
        <!--Column Contact Us-->
        <div fxLayout="row wrap" fxLayout.sm="column wrap" fxLayout.xs="column wrap" fxLayoutAlign="center start"
          fxLayoutAlign.sm="start center" fxLayoutAlign.xs="start center" class="container-contact-us">
          <div fxLayout="column" class="header-contact-us">
            <p class="titles-h1 text-header-contact-us">Contáctanos</p>
          </div>
          <!--Content Contact Us-->
          <div fxLayout="column" fxLayout.sm="row wrap" fxLayout.xs="row wrap" class="content-contact-us"
            fxLayoutAlign="start center" fxLayoutAlign.sm="center start" fxLayoutAlign.xs="center start">
            <div fxLayout="column wrap">
              <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="center center">
                <p class="body-regular-text" id="title-contact-us">Si tienes algún asunto diferente, escríbenos a través
                  de nuestro formulario.
                </p>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <button mat-raised-button color="primary" class="button-round-active-3 button-write" (click)="onContact()">Escribir</button>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </div>
  </mat-card>
</div>
