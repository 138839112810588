import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Componente Main
 */
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  /**
   * Formulario
   */
  public frmGroup: FormGroup;
  /**
   * FormBuilder
   */
  private readonly frmBuilder: FormBuilder;
  /**
   * Servicio para registrar y mostrar iconos usados por el componente `<mat-icon>`.
   */
  private readonly matIconRegistry: MatIconRegistry;
  /**
   * DomSanitizer ayuda a prevenir errores de Cross Site Scripting Security (XSS) al desinfectar valores para ser seguros de usar en los diferentes contextos DOM.
   */
  private readonly domSanitizer: DomSanitizer;

  public constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, frmBuilder: FormBuilder) {
    this.matIconRegistry = matIconRegistry;
    this.domSanitizer = domSanitizer;
    this.frmBuilder = frmBuilder;

    this.matIconRegistry.addSvgIcon(
      `choose_file`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/images/Gráficos_Mesa de trabajo1.svg'),
    );

    this.frmGroup = this.frmBuilder.group({
      name: ['', Validators.required],
      city: ['', Validators.required],
    });
  }

  public ngOnInit(): void {
  }

}
