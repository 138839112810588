import { Injectable, Injector } from '@angular/core';
import {
  CaptchaApiService,
  CaptchaDto,
  EncryptionCaptchaDto,
  EncryptionInteractionDto,
  InteractionApiService,
  InteractionDto,
  ReCaptchaDto,
} from '@prf/interaction';
import {
  DataRequestDto,
  DocumentsDto,
  EncryptionPostDocumentDto,
  EncryptionPostFormDto,
  OnBaseApiService,
  ResponseDocumentDto,
  ResponseFormDto,
} from '@prf/on-base';
import { QuerySubjectApiService, QuerySubjectDto } from '@prf/query-subject';
import { TypeCustomerApiService, TypeCustomerDto } from '@prf/type-customer';
import { TypeDocumentApiService, TypeDocumentDto } from '@prf/type-document';
import {
  DataRequestGestionDto,
  DataRequestSaveDto,
  EncryptionDataTreatmentDto,
  TratamientoDatosDto,
  WsDataTreatmentApiService,
} from '@prf/ws-data-treatment';
import { Observable } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';

import { AuthenticationService } from '../../../services/authentication.service';
import { FacadeBase } from '../../../shared/base/class/facade.base';
import { GlobalState } from '../../../shared/base/class/global.state';
import { EncryptService } from '../../../shared/services/encrypt/encrypt.service';
import { FileData } from '../../Models/file-data.model';

/**
 * Facade Contact-Us
 */
@Injectable()
export class ContactUsFacade extends FacadeBase {
  /**
   * Servicio encargado de interactuar con el servicio de encriptacion
   */
  private readonly encryptService: EncryptService;
  /**
   * Servicio encargado de interactuar con el MS de Type Document
   */
  private readonly typeDocumentApiService: TypeDocumentApiService;
  /**
   * Servicio encargado de interactuar con el MS de Type Customer
   */
  private readonly typeCustomerApiService: TypeCustomerApiService;
  /**
   * Servicio encargado de interactuar con el MS de Query Subject
   */
  private readonly querySubjectApiService: QuerySubjectApiService;
  /**
   * Servicio encargado de interactuar con el Ms de Interaction en el endpoint Captcha
   */
  private readonly captchaApiService: CaptchaApiService;
  /**
   * Servicio encargado de interactuar con el Ms de Ws Data Treatment
   */
  private readonly wsDataTreatmentApiService: WsDataTreatmentApiService;
  /**
   * Servicio encargado de administrar la autenticación
   */
  private readonly authenticationService: AuthenticationService;
  /**
   * Servicio encargado de interactuar con el Ms de On Base
   */
  private readonly onBaseApiService: OnBaseApiService;
  /**
   * Servicio encargado de interactuar con el Ms de Interaction en el endpoint Interaction
   */
  private readonly interactionApiService: InteractionApiService;
  /**
   * Observable - Tipos de documentos
   */
  public get typeDocuments$(): Observable<TypeDocumentDto[]> {
    return this.state.typeDocuments$;
  }
  /**
   * Observable - Tipos de cliente
   */
  public get typeCustomers$(): Observable<TypeCustomerDto[]> {
    return this.state.typeCustomers$;
  }
  /**
   * Observable - Asuntos de consulta
   */
  public get querySubjects$(): Observable<QuerySubjectDto[]> {
    return this.state.querySubjects$;
  }
  /**
   * Observable que contiene la información de los archivos subidos
   */
  public get dataSelected$(): Observable<FileData[]> {
    return this.state.fileUpload$;
  }
  /**
   * Observable que contiene la información de los archivos subidos
   */
  public get infoDisableButtonFileUp$(): Observable<boolean> {
    return this.state.disableButtonFileUp$;
  }

  /**
   * Constructor
   * @param state estado global
   * @param injector Servicio para obetner acceso a dependency control
   * @param typeDocumentApiService Servicio encargado  de interactuar con el microservicio de tipo de documento
   * @param typeCustomerApiService Servicio encargado  de interactuar con el microservicio de tipo de cliente
   * @param querySubjectApiService Servicio encargado  de interactuar con el microservicio de asunto de consulta
   * @param onBaseApiService Servicio encargado de interactuar con el microservicio de envio de correo
   * @param captchaApiService Servicio encargado de interactuar con el Ms de Interaction en el endpoint Captcha
   * @param authenticationService Servicio encargado de los datos de Autenticación
   */
  public constructor(
    state: GlobalState,
    injector: Injector,
    typeDocumentApiService: TypeDocumentApiService,
    typeCustomerApiService: TypeCustomerApiService,
    querySubjectApiService: QuerySubjectApiService,
    wsDataTreatmentApiService: WsDataTreatmentApiService,
    captchaApiService: CaptchaApiService,
    authenticationService: AuthenticationService,
    onBaseApiService: OnBaseApiService,
    interactionApiService: InteractionApiService,
    encryptService: EncryptService,
  ) {
    super(injector, state);
    this.typeDocumentApiService = typeDocumentApiService;
    this.typeCustomerApiService = typeCustomerApiService;
    this.querySubjectApiService = querySubjectApiService;
    this.wsDataTreatmentApiService = wsDataTreatmentApiService;
    this.captchaApiService = captchaApiService;
    this.authenticationService = authenticationService;
    this.onBaseApiService = onBaseApiService;
    this.interactionApiService = interactionApiService;
    this.encryptService = encryptService;
  }

  /**
   * Metodo encargado de consultar los tipos de documentos
   */
  public allTypeDocuments(fn: () => void) {
    this.spinner.show();
    this.typeDocumentApiService
      .all()
      .pipe(
        map((data) => data.filter((x) => x.state === true)),
        finalize(() => {
          this.spinner.hide();
          fn();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.state.setTypeDocuments(response);
        },
        () => {
          this.spinner.hide();
        },
      );
  }

  /**
   * Metodo encargado de consultar los tipos de clientes
   */
  public allTypeCustomers(fn: () => void) {
    this.spinner.show();
    this.typeCustomerApiService
      .all()
      .pipe(
        map((data) => data.filter((x) => x.state === true)),
        map((data) => data.filter((x) => x.state === true)),
        finalize(() => {
          this.spinner.hide();
          fn();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.state.setTypeCustomers(response);
        },
        () => {
          this.spinner.hide();
        },
      );
  }

  /**
   * Metodo encargado de consultar los Asuntos de consulta
   */
  public allQuerySubjects() {
    this.spinner.show();
    this.querySubjectApiService
      .all()
      .pipe(
        map((data) => data.filter((x) => x.state === true)),
        finalize(() => this.spinner.hide()),
        takeUntil(this.destroy$),
      )
      .subscribe(
        (response) => {
          this.spinner.hide();
          this.state.setQuerySubjects(response);
        },
        () => {
          this.spinner.hide();
        },
      );
  }

  /*
   **Metodo  que trae  los base 64 de tratamiento de datos
   */
  public getTratamientoDatos(): Observable<TratamientoDatosDto> {
    this.spinner.show();
    const data: DataRequestGestionDto = {
      codigoReferencia: '1004',
      funcionalidad: 'VENTANILLA',
      nombreReferencia: '1',
      producto: 'HABEAS_DATA',
      tipoDocumento: 'TRATAMIENTODATOS',
    };

    return this.wsDataTreatmentApiService.getWsDataTreatment(data).pipe(takeUntil(this.destroy$));
  }

  /*
   **Metodo  que trae  los base 64 de terminos y condiciones
   */
  public getTermsAndConditions(): Observable<TratamientoDatosDto> {
    this.spinner.show();
    const data: DataRequestGestionDto = {
      codigoReferencia: '1005',
      funcionalidad: 'VENTANILLA',
      nombreReferencia: '2',
      producto: 'USO_PLATAFORMA',
      tipoDocumento: 'TERMINOSYCONDICIONES',
    };

    return this.wsDataTreatmentApiService.getWsDataTreatment(data).pipe(takeUntil(this.destroy$));
  }

  /*
   **Metodo para aceptar tratamiento de datos
   */
  public aceptacionTratamientoDatos(tipoAfi: string, numberAfi: string): Observable<EncryptionDataTreatmentDto> {
    this.spinner.show();
    const dataRequestSave: DataRequestSaveDto = {
      aceptaciones: [
        {
          idDocumento: 429,
          aplicacion: 'VENTANILLA',
          ip: '190.253.152.137',
          tipoIdAfil: tipoAfi,
          numeroIdAfil: Number(numberAfi),
          transaccion: 'VENTANILLA',
          conceptoAceptacion: 'HABEAS_DATA_USO_APP',
          respuesta: 'SI',
          usuarioCreacion: '1073680216',
        },
      ],
    };

    const dataRequestSaveJson = JSON.stringify(dataRequestSave);

    const dataRequestSaveEncrypt = this.encryptService.toEncrypt(dataRequestSaveJson);

    const request: EncryptionDataTreatmentDto = {
      data: dataRequestSaveEncrypt,
    };

    return this.wsDataTreatmentApiService.PostWsDataTreatment(request).pipe(takeUntil(this.destroy$));
  }
  /*
   **Metodo para aceptar terminos y condiciones
   */
  public acceptanceTemsAndConditions(tipoAfi: string, numberAfi: string): Observable<EncryptionDataTreatmentDto> {
    this.spinner.show();
    const dataRequestSave: DataRequestSaveDto = {
      aceptaciones: [
        {
          idDocumento: 341,
          aplicacion: 'VENTANILLA',
          ip: '10.34.2.98',
          tipoIdAfil: tipoAfi,
          numeroIdAfil: Number(numberAfi),
          transaccion: 'VENTANILLA',
          conceptoAceptacion: 'ACEPTA_TERMINOS',
          respuesta: 'SI',
          usuarioCreacion: 'SISISGO',
        },
      ],
    };

    const dataRequestSaveJson = JSON.stringify(dataRequestSave);

    const dataRequestSaveEncrypt = this.encryptService.toEncrypt(dataRequestSaveJson);

    const request: EncryptionDataTreatmentDto = {
      data: dataRequestSaveEncrypt,
    };

    return this.wsDataTreatmentApiService.PostWsDataTreatment(request).pipe(takeUntil(this.destroy$));
  }
  /**
   * Método encargado de consultar el web service de Authentication a fin de validar el Captcha
   * @param captcha Representa la informacion de CaptchaDto
   */
  public auth(captcha: CaptchaDto, fn: (flag: boolean) => void) {
    this.spinner.show();

    const captchaJson = JSON.stringify(captcha);

    const captchaEncrypt = this.encryptService.toEncrypt(captchaJson);

    const request: EncryptionCaptchaDto = {
      data: captchaEncrypt,
    };

    this.captchaApiService
      .postCaptcha(request)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          const captchaDecrypt = this.encryptService.toDecrypt(response.data || '');

          const captchaDeserealized = JSON.parse(captchaDecrypt) as ReCaptchaDto;

          if (captchaDeserealized.isValidAuthentication) {
            this.state.setReCaptcha(captchaDeserealized);
            this.state.setToken(captchaDeserealized.authenticationToken || '');
            this.authenticationService.setAuthenticated = captchaDeserealized.isValidAuthentication || false;
            this.authenticationService.setToken = captchaDeserealized.authenticationToken || '';
            this.spinner.hide();
            fn(true);
          } else {
            this.spinner.hide();
            fn(false);
          }
        },
        () => {
          fn(false);
          this.spinner.hide();
        },
      );
  }
  /**
   * Realiza una solicitud al servicio web de On Base para enviar datos y documentos encriptados.
   * @param data Objeto que contiene los datos y documentos estructurados para enviar a On Base.
   * @param fn Función de retorno que indica el resultado de la operación.
   */
  public onBase(data: DataRequestDto, fn: (flag: boolean) => void) {
    // Mostrar el spinner de carga
    this.spinner.show();

    // Arreglo para almacenar los archivos adjuntos convertidos a DTO de documentos
    const attachedFiles: DocumentsDto[] = [];

    // Arreglo para almacenar los datos encriptados de los documentos
    const dataEncryptList: EncryptionPostDocumentDto[] = [];

    // Procesar los datos del formulario para enviar a On Base, si están presentes
    if (data.onBaseDto) {
      // Convertir el objeto a JSON
      const dataJson = JSON.stringify(data.onBaseDto);

      // Encriptar los datos del formulario
      const dataEncrypt = this.encryptService.toEncrypt(dataJson);

      // Construir la solicitud para enviar datos encriptados al servicio web de On Base
      const request: EncryptionPostFormDto = {
        encryptedFormData: dataEncrypt,
      };

      // Llamar al servicio web para enviar datos encriptados
      this.onBaseApiService
        .PostDataForm(request)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response) => {
            // Decodificar la respuesta encriptada recibida
            const dataDecrypt = this.encryptService.toDecrypt(response.encryptedFormData || '');

            // Deserializar la respuesta JSON obtenida
            const dataDeserealized = JSON.parse(dataDecrypt) as ResponseFormDto;

            // Verificar si la operación fue exitosa o no
            if (dataDeserealized.Description) {
              // Ocultar el spinner de carga y llamar a la función de retorno con `false` (error)
              this.spinner.hide();
              fn(false);
            } else {
              // Procesar los documentos adjuntos, si están presentes en la solicitud
              if (data.documents && data.documents.length > 0) {
                for (let i = 0; i < data.documents.length; i++) {
                  const docList = data.documents[i];

                  // Verificar si el documento actual tiene datos multipartForm
                  if (docList && docList.multipartForm) {
                    // Crear un objeto DocumentsDto con los datos del archivo adjunto
                    const fileData: DocumentsDto = {
                      multipartForm: docList.multipartForm,
                      documentTypeName: docList.documentTypeName,
                      sourceApplication: docList.sourceApplication,
                      jsonData: docList.jsonData,
                      kWIDs: docList.kWIDs,
                      kTGIDs: docList.kTGIDs,
                    };

                    // Agregar el objeto DocumentsDto al arreglo de archivos adjuntos
                    attachedFiles.push(fileData);
                  }

                  // Convertir el objeto a JSON y encriptarlo
                  const dataJson = JSON.stringify(attachedFiles[i]);
                  const dataEncrypt = this.encryptService.toEncrypt(dataJson);

                  // Crear una solicitud de encriptación para cada documento adjunto
                  const request: EncryptionPostDocumentDto = {
                    encryptedDocumentData: dataEncrypt,
                  };

                  // Agregar la solicitud de encriptación al arreglo de solicitudes de encriptación
                  dataEncryptList.push(request);
                }

                this.onBaseApiService
                  .PostDocuments(dataEncryptList)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(
                    (response) => {
                      // Procesar la respuesta recibida para cada documento encriptado enviado
                      for (const responseData of response) {
                        // Decodificar la respuesta encriptada recibida
                        const dataDecrypt = this.encryptService.toDecrypt(responseData.encryptedDocumentData || '');

                        // Deserializar la respuesta JSON obtenida
                        const dataDeserialized = JSON.parse(dataDecrypt) as ResponseDocumentDto;

                        // Verificar si la operación fue exitosa o no
                        if (dataDeserialized.Description) {
                          // Ocultar el spinner de carga y llamar a la función de retorno con `false` (error)
                          this.spinner.hide();
                          fn(false);
                        } else {
                          // Ocultar el spinner de carga y llamar a la función de retorno con `true` (éxito)
                          this.spinner.hide();
                          fn(true);
                        }
                      }
                    },
                    () => {
                      // En caso de error, llamar a la función de retorno con `false` y ocultar el spinner de carga
                      fn(false);
                      this.spinner.hide();
                    },
                  );
              } else {
                // Ocultar el spinner de carga y llamar a la función de retorno con `true` (éxito)
                fn(true);
                this.spinner.hide();
              }
            }
          },
          () => {
            // En caso de error, llamar a la función de retorno con `false` y ocultar el spinner de carga
            fn(false);
            this.spinner.hide();
          },
        );
    }
  }

  /**
   * Metodo encargado de guardar la interación
   * @param interaction Representa la informacion de InteractionDto
   */
  public saveInteraction(interaction: InteractionDto, fn: (flag: boolean, id: number) => void) {
    this.spinner.show();

    const interactionJson = JSON.stringify(interaction);

    const interactionEncrypt = this.encryptService.toEncrypt(interactionJson);

    const request: EncryptionInteractionDto = {
      data: interactionEncrypt,
    };

    this.interactionApiService
      .PostInteraction(request)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          const interactionDecrypt = this.encryptService.toDecrypt(response.data || '');

          const interactionDeserealized = JSON.parse(interactionDecrypt) as InteractionDto;

          if (interactionDeserealized.id) {
            fn(true, interactionDeserealized.id);
          } else {
            fn(false, 0);
          }
        },
        () => {
          fn(false, 0);
        },
      );
  }
}
