<div fxLayout="row wrap" class="banner-gradient">
  <div fxLayout="row">
    <img class="logo-send-banner" src="../../../../assets/images/Gráficos-16.svg" alt="Logo envio correo" />
  </div>
  <div fxLayout="row" fxLayoutAlign="start" fxLayoutAlign.sm="center center">
    <p class="titles-h2 title-banner">Ventanilla Virtual</p>
  </div>
  <div fxLayout="row">
    <img class="logo-company-banner" src="../../../../assets/images/Gráficos-15.svg" alt="Logo porvenir contacto" />
  </div>
</div>
