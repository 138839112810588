import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Componente de Modals
 */
@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss'],
})
export class ModalsComponent implements OnInit {
  /**
   * Referencia al mismo componente
   */
  private readonly matDialog: MatDialogRef<ModalsComponent>;
  /**
   * Datos
   */
  public datos: string;
  /**
   * Titulo
   */
  public title: string;

  /**
   * Constructor
   * @param data Data
   * @param matDialog Reference to a dialog opened via the MatDialog service
   */
  // tslint:disable-next-line: no-parameter-properties
  public constructor(@Inject(MAT_DIALOG_DATA) public data: string, matDialog: MatDialogRef<ModalsComponent>) {
    this.matDialog = matDialog;
    this.title = data.split(',')[1];
    // tslint:disable-next-line: prefer-template
    this.datos = `<embed src="data:application/pdf;base64,` + data.split(',')[0] + `" type="application/pdf" width="800px" height="450px">`;
  }

  /**
   * On Init
   */
  public ngOnInit(): void { }

  /**
   * onClose
   */
  public onClose() {
    this.matDialog.close();
  }
}
