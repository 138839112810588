import { Component, OnInit } from '@angular/core';

/**
 * Componente Unauthorized
 */
@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {

  public ngOnInit(): void { }

}
