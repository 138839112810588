import { Component } from '@angular/core';

/**
 * Componente Raiz
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * Titulo
   */
  public title = 'contacto-porvenir';
}
