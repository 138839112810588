import { Component, OnInit } from '@angular/core';

/**
 * Componente Error
 */
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {

  public ngOnInit(): void { }

}
